import { decodeToken } from './sso';

/**
 * Updates the user on the Intercom plugin.
 */
export const update = async () => {
  if (window.Intercom) {
    console.debug('[Intercom] Updating user.');
    const decodedToken = await decodeToken();
    if (decodedToken) {
      window.Intercom('update', {
        app_id: 'k37j0e9b',
        email: decodedToken.Email,
        name: (decodedToken.FirstName || decodedToken.LastName) ? `${decodedToken.FirstName} ${decodedToken.LastName}` : null,
        user_id: decodedToken.UserId,
        company: {
          company_id: decodedToken.CompanyId,
          name: decodedToken.CompanyName,
        },
      });
      console.debug('[Intercom] Updated user.');
    }
  }
};

const init = async () => {
  (function () {
    var w = window;
    var ic = w.Intercom;
    if (typeof ic === 'function') {
      ic('reattach_activator');
      ic('update', w.intercomSettings);
    } else {
      var d = document;
      var i = function () {
        i.c(arguments);
      };
      i.q = [];
      i.c = function (args) {
        i.q.push(args);
      };
      w.Intercom = i;
      var l = function () {
        var s = d.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        s.src = 'https://widget.intercom.io/widget/k37j0e9b';
        var x = d.getElementsByTagName('script')[0];
        x.parentNode.insertBefore(s, x);
      };
      if (w.attachEvent) {
        w.attachEvent('onload', l);
      } else {
        w.addEventListener('load', l, false);
      }
    }
  })();

  await update();
};

init()
  .then();

export const hide = () => {
  document.querySelector('.intercom-lightweight-app').style.display = 'none';
};

export const show = () => {
  document.querySelector('.intercom-lightweight-app').style.display = 'block';
};

export default {
  update,
  hide,
  show,
};
