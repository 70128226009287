import { lorax } from '../urls';
import { decode } from './token';

export default async (companyId) => {
  console.debug('[SSO] Wrong company: Called.');
  const token = await decode();
  if (!token || token.CompanyId === companyId) {
    console.debug('[SSO] Wrong company: Matched.');
    return;
  }

  console.debug('[SSO] Wrong company: Redirecting.');
  window.location.href = `${lorax}/account/change-company?company-id=${companyId}&redirect=${encodeURIComponent(window.location.href)}`;
};
