import { KEY } from './token';
import delay from '../helpers/delay';
import { logout } from './index';

// Handler that transfers sessionStorage from one tab to another based on a temporary localStorage 'SessionTokenTransfer'.
export const SETSESSIONKEY = 'SetSessionToken';
const setHandler = (event) => {
  if (event.key !== SETSESSIONKEY) {
    return;
  }

  console.debug('[SSO] Set Session Storage Listener: Called.');
  const token = sessionStorage.getItem(KEY);
  const newToken = localStorage.getItem(SETSESSIONKEY);
  if (!newToken) {
    console.debug('[SSO] Set Session Storage Listener: No new token.');
  } else if (newToken === 'logout') {
    console.debug('[SSO] Set Session Storage Listener: Logout.');
    logout();
  } else if (!token) {
    console.debug('[SSO] Set Session Storage Listener: Set.');
    sessionStorage.setItem(KEY, newToken);
  } else if (token !== newToken) {
    console.debug('[SSO] Set Session Storage Listener: Changed.');
    sessionStorage.setItem(KEY, newToken);
    window.location.reload();
  } else {
    console.debug('[SSO] Set Session Storage Listener: Unchanged.');
  }
};
window.addEventListener('storage', setHandler, false);

export const synchronizeSessionTokens = async (token) => {
  if (!token) {
    localStorage.setItem(SETSESSIONKEY, 'logout');
  } else {
    localStorage.setItem(SETSESSIONKEY, token);
  }
  await delay(100);
  localStorage.removeItem(SETSESSIONKEY);
};

export const GETSESSIONKEY = 'GetSessionToken';
const getHandler = async (event) => {
  if (event.key !== GETSESSIONKEY) {
    return;
  }

  console.debug('[SSO] Get Session Storage Listener: Called.');
  const token = sessionStorage.getItem(KEY);
  if (!token) {
    console.debug('[SSO] Get Session Storage Listener: No session token.');
    return;
  }

  console.debug('[SSO] Get Session Storage Listener: Synced.');
  await synchronizeSessionTokens(token);
};
window.addEventListener('storage', getHandler, false);

export const sendSynchronizeRequest = async () => {
  localStorage.setItem(GETSESSIONKEY, 'true');
  await delay(100);
  localStorage.removeItem(GETSESSIONKEY);
};
