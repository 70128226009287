import { phoenix } from '../urls';
import app from '../app';
import login from './login';
import {
  SETSESSIONKEY, GETSESSIONKEY, synchronizeSessionTokens, sendSynchronizeRequest,
} from './sessionStorageTransfer';
import './localStorageTransfer';
import { init as initPreferences } from './preferences';

export const KEY = 'RiskAliveToken';

/**
 *  A method to set the token.
 */
export const set = async (newToken) => {
  if (!newToken) {
    console.debug('[SSO] Set token: Null.');
    localStorage.removeItem(KEY);
    localStorage.removeItem(SETSESSIONKEY);
    localStorage.removeItem(GETSESSIONKEY);
    sessionStorage.removeItem(KEY);
    await synchronizeSessionTokens(null);
  } else if (app === 'Lorax') {
    console.debug('[SSO] Set token: Local storage.');
    localStorage.setItem(KEY, newToken);
  } else {
    console.debug('[SSO] Set token: Session storage.');
    sessionStorage.setItem(KEY, newToken);
    await synchronizeSessionTokens(newToken);
  }

  window.riskalive.intercom.update();
};

const handleTransferId = async () => {
  console.debug('[SSO] Handle transfer id: Called.');
  const url = new URL(window.location.href);
  const transferId = url.searchParams.get('transfer-id');

  if (transferId) {
    console.debug('[SSO] Handle transfer id: Detected.');

    // Remove the transfer-id from the url.
    url.searchParams.delete('transfer-id');
    window.history.replaceState(null, '', url.href);

    const response = await fetch(`${phoenix}/api/account/token/transfer/${transferId}`);
    const data = await response.json();

    if (response.ok) {
      console.debug('[SSO] Handle transfer id: Found.');
      await set(data.token);
      initPreferences(data.preferences);
    } else {
      console.debug('[SSO] Handle transfer id: Not found.');
      await login();
    }
  }
};

const handleTransferIdPromise = new Promise((resolve) => {
  console.debug('[SSO] Handle transfer id promise: Called.');
  handleTransferId()
    .then(() => {
      resolve();
      console.debug('[SSO] Handle transfer id promise: Resolved.');
    });
});

/**
 *  A method to get token.
 * @returns token.
 */
export const get = async () => {
  console.debug('[SSO] Get token: Called.');
  await handleTransferIdPromise;

  if (app === 'Lorax') {
    console.debug('[SSO] Get token: Local storage.');
    return localStorage.getItem(KEY);
  }

  console.debug('[SSO] Get token: Session storage.');
  const sessionToken = sessionStorage.getItem(KEY);
  if (!sessionToken) {
    console.debug('[SSO] Get token: Send session synchronization request.');
    await sendSynchronizeRequest();
    return sessionStorage.getItem(KEY);
  }

  return sessionToken;
};

export const decode = async () => {
  console.debug('[SSO] Decode token: Called.');

  try {
    const myToken = await get();
    if (!myToken) {
      return null;
    }

    return JSON.parse(atob(myToken.split('.')[1]));
  } catch (err) {
    console.error(err);
    return null;
  }
};

/**
 * A method to check if token is expired.
 */
export const isExpired = async () => {
  console.debug('[SSO] Is expired: Called.');
  const decodedToken = await decode();
  if (decodedToken && decodedToken.exp) {
    const result = decodedToken.exp <= Date.now() / 1000;
    console.debug(`[SSO] Is expired: ${result}.`);
    return result;
  }

  console.debug('[SSO] Is expired: Force true.');
  return true;
};
