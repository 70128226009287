import * as urls from './urls';

export const get = (href) => {
  let baseUrl = null;
  switch (href.split('/', 1)[0]) {
    case 'lorax': baseUrl = urls.lorax; break;
    case 'medusa': baseUrl = urls.medusa; break;
    case 'nanook': baseUrl = urls.nanook; break;
    case 'sphinx': baseUrl = urls.sphinx; break;
    case 'titan': baseUrl = urls.titan; break;
    default: break;
  }

  return href.replace(/^(?:lorax|medusa|nanook|sphinx|titan)\//, `${baseUrl}/`);
};

export const initialize = () => {
  const elements = document.querySelectorAll('[data-riskalive-href]');
  for (let i = 0; i < elements.length; i++) {
    const element = elements[i];
    const url = element.attributes['data-riskalive-href'].value;

    let linkElement = element;
    if (element.tagName.toUpperCase() !== 'A') {
      linkElement = element.querySelector('a');
    }

    linkElement.href = get(url);
    element.removeAttribute('data-riskalive-href');
  }
};
