function getEnvironment() {
  if (window.riskaliveOverrides && window.riskaliveOverrides.environment) {
    return window.riskaliveOverrides.environment;
  }

  const hostname = window.location.hostname.toLowerCase();
  if (hostname === 'localhost' || hostname === '127.0.0.1' || hostname.endsWith('-local.riskalive.com')) {
    return 'Local';
  }

  if (hostname.endsWith('-dev.riskalive.com')) {
    return 'Development';
  }

  if (hostname.endsWith('-uat.riskalive.com')) {
    return 'UAT';
  }

  if (hostname.endsWith('-staging.riskalive.com')) {
    return 'Staging';
  }

  return 'Production';
}

export default getEnvironment();
